import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  const OpenLinkedin = () => {
    window.open(
      "https://www.linkedin.com/in/franco-valentín-mendoza-039127201",
      "_blank"
    );
  };
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>About me Testing CloudFlare deploy</p>
        <button className="btn" onClick={OpenLinkedin}>
          My linkedin
        </button>
      </header>
    </div>
  );
}

export default App;
